import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import H1 from '../components/H1'

export default function Forms() {
  return (
    <Layout>
      <h1>Forms</h1>
      <p>
        Forms have been moved to <Link to="/resources/">Resources</Link>.
      </p>
    </Layout>
  )
}
